import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns = ({
  location,
}) => {
  const title = "是否有可能创建一个过去转发活动参与者的名单？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="我有一个推特转发活动（即时赢），是在没有PARKLoT的情况下进行的。 我能否得到一份参加该活动的用户名单？"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              我有一个推特转发活动，是在没有PARKLoT的情况下进行的。
              我以后能不能检索到参加这个活动的用户？
            </h1>
            <p className="qa-page__answer">
              联系。
              <br />
            </p>
            <p className="qa-page__text">
              由于Twitter的规范，转发用户通常只能获得最后100次转发。
              <br />
              然而，在某些情况下，有可能使用其他方法获得转发用户。
              <br />
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  如果没有专门的工具，转发用户只能得到最后100个转发用户的信息
                </h2>

                <p className="qa-page__text">
                  在开展转发活动时，这里有一些需要特别注意的点。
                </p>

                <p className="qa-page__text">
                  如果你试图从Twitter应用程序中检查转发用户，你实际上只能看到最近的100个用户。
                </p>

                <h3 className="qa-page__h3">PARKLoT的转发用户收集机制</h3>

                <p className="qa-page__text">
                  PARKLoT有一个系统在后台不断运行，从活动开始后立即收集转发用户的数据，直到活动结束。
                </p>

                <h3 className="qa-page__h3">计划你对该工具的使用!</h3>

                <p className="qa-page__text">
                  为了避免在进行抽奖时出现恐慌，考虑在开展转发活动时使用该工具。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns
